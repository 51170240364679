window.addEventListener("heyflow-screen-view", (event) => {
    try {
        if (event.detail.screenName === "registration") {
            var ipAddressField = document.getElementById("ipaddress");
            fetch("https://checkip.amazonaws.com/")
            .then((res) => res.text())
            .then((data) => {
                if (ipAddressField) {
                    ipAddressField.value = data.replace("\n", "");
                }
            });
        }
    } catch (err) {}
});

window.addEventListener("heyflow-submit", (event) => {
    // console.log('heyflow submit:', event.detail);
});

window.addEventListener("heyflow-init", (event) => {
    //console.log('heyflow init:', event.detail);
});
document.addEventListener("DOMContentLoaded", function () {
    // Retrieve the company variable from URL or global data
    const urlParams = new URLSearchParams(window.location.search);
    let company = urlParams.get("company") || "default"; // Use 'default' if no company is provided

    // Use only the first word of the company variable
    company = company.split(" ")[0];

    // Define iframe sources for each company
    const content = {
        Naturvel: {
            privacy: "https://www.123-tongain.com/cgi-bin/global.pl?jMrqkowoWgLgiirkrQlnwhzmtoMinoJOqlgjiGOpwkrmqRyNggXlxkwOiIqsysLgOmvgiKmNyNgjkSqxjonMgJpujxGrQgjgwOOljopssghwwrQligmgG",
            terms: "https://www.123-tongain.com/sc/todo=show_html&html_pk=13"
        },
        toleadoo: {
            privacy: "https://www.kdo-pournous.com/cgi-bin/global.pl?qPgrGJhKMlRgghwWgooisLgOmvgiKmzyOzzXgsjxKwHnishiLypkIKgmoxspkngHyztwGthlxwgmihvKGogLGIGoxgxykkIoJOqngjmsMxGkuxvKGygmgG",
            terms: "https://www.kdo-pournous.com/sc/todo=show_html&html_pk=16"
        },
        default: {
            privacy: "https://www.123-tongain.com/cgi-bin/global.pl?HKqmwyKKWgggjhgJOqsgsPgrGJhKMlRghwouMsLgOmvgiKmRyNXkSqxjoRzmGthjGrQlhwhxqukKilzlPLtwgsxiKyouhrlmRptoxihjkopsxvKGSmgG",
            terms: "https://www.123-tongain.com/sc/todo=show_html&html_pk=13"
        }
        };

        // Set iframe sources based on the company variable
        const companyContent = content[company];

        document.getElementById("privacy-content").innerHTML = `<iframe src="${companyContent.privacy}" frameborder="0"></iframe>`;
        document.getElementById("terms-content").innerHTML = `<iframe src="${companyContent.terms}" frameborder="0"></iframe>`;

        // Modal functionality
        const modals = {
            privacy: document.getElementById("privacy-modal"),
            terms: document.getElementById("terms-modal")
        };

        document.getElementById("privacy-link").onclick = function () {
            modals.privacy.style.display = "block";
        };

        document.getElementById("terms-link").onclick = function () {
            modals.terms.style.display = "block";
        };

        document.getElementById("close-privacy").onclick = function () {
            modals.privacy.style.display = "none";
        };

        document.getElementById("close-terms").onclick = function () {
            modals.terms.style.display = "none";
        };

        // Close modal if clicked outside of content
        window.onclick = function (event) {
            if (event.target == modals.privacy) {
                modals.privacy.style.display = "none";
            }
            if (event.target == modals.terms) {
                modals.terms.style.display = "none";
            }
        };
    });